<template>
  <b-container
    fluid
    style="height: 100vh;"
  >
    <b-row
      align-v="center"
      class="h-100"
    >
      <b-col
        cols="12"
        md="4"
        class="mx-auto"
      >
        <b-card class="mt-3">
          <logo-app />

          <b-form @submit="onSubmit">
            <b-form-group
              id="input-group-1"
              label="Correo electrónico"
              label-for="input-1"
            >
              <b-form-input
                id="input-1"
                v-model="form.email"
                type="email"
                placeholder="Ingrese su correo"
                required
              />
            </b-form-group>

            <div class="d-flex justify-content-between align-items-center">
              <router-link :to="{name: 'login'}">
                Iniciar Sesión
              </router-link>

              <b-spinner
                v-if="loading"
                small
              />
              <b-button
                v-else
                type="submit"
                variant="dark"
              >
                Enviar email
              </b-button>
            </div>
          </b-form>
        </b-card>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import apiFelberg from "@/api";
import LogoApp from "@/components/LogoApp";

export default {
  components: {LogoApp},
  data() {
    return {
      form: {
        email: '',
      },
      loading: false
    }
  },
  methods: {
    async onSubmit(event) {
      event.preventDefault()

      this.loading = true
      try {
        const response = await apiFelberg.post('/forgot-password', this.form)

        if(response.status === 200){
          await this.$router.push({name: 'login'})
        }
      } catch (error) {
        console.error(error)
      }
      this.loading = false
    }
  }
}
</script>
