<template>
  <b-container
    fluid
    style="height: 100vh;"
  >
    <b-row
      align-v="center"
      class="h-100"
    >
      <b-col
        cols="12"
        sm="8"
        md="6"
        lg="4"
        class="mx-auto"
      >
        <b-card class="mt-3">
          <logo-app />
          <b-form @submit="onSubmit">
            <b-form-group
              id="input-group-1"
              label="Correo electrónico"
              label-for="input-1"
            >
              <b-form-input
                id="input-1"
                v-model="form.email"
                type="email"
                placeholder="Ingrese su correo"
                required
              />
            </b-form-group>

            <b-form-group
              id="input-group-2"
              label="Contraseña"
              label-for="input-2"
            >
              <b-form-input
                id="input-2"
                v-model="form.password"
                type="password"
                placeholder="Ingrese su contraseña"
                required
              />
            </b-form-group>

            <div class="d-flex justify-content-between align-items-center">
              <router-link :to="{name: 'forgot-password'}">
                ¿Olvidó su contraseña?
              </router-link>
              <b-spinner
                v-if="loading"
                small
              />
              <b-button
                v-else
                type="submit"
                variant="dark"
              >
                Iniciar Sesión
              </b-button>
            </div>
          </b-form>
        </b-card>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import apiFelberg from "@/api";
import Cookies from "js-cookie";
import LogoApp from "@/components/LogoApp";

export default {
  components: {LogoApp},
  data() {
    return {
      form: {
        email: '',
        password: ''
      },
      loading: false,
    }
  },
  methods: {
    async onSubmit(event) {
      this.loading = true
      event.preventDefault()

      try {
        /* eslint-disable */
        const urlGetCookie = `${process.env.VUE_APP_BASE_URL}/sanctum/csrf-cookie`
        /* eslint-enable */

        await apiFelberg.get(urlGetCookie).then( async () => {
          let response
          try {
            response = await apiFelberg.post('/login', this.form)
          } catch (error) {
            if (error.response.status === 404) {
              this.$bvToast.toast(error.response.data.message, {
                title: `Error al enviar los datos`,
                variant: 'danger',
                solid: true,
                toaster: 'b-toaster-top-center',
                autoHideDelay: 2000,
                noCloseButton: true
              })
            }
          }

          if(response?.status === 200){
            Cookies.set('token', response.data.data.token)
            await this.$router.push({name: 'home'})
          }
        });
      } catch (error){
        console.error(error)
      }

      this.loading = false
    },
  }
}
</script>
